import { Component, OnInit } from '@angular/core';
import { AppRoutes } from '../../app.constants';

@Component({
  selector: 'app-dash-sidenav',
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent implements OnInit {
  AppRoutes = AppRoutes;

  constructor() { }

  ngOnInit() {
  }

}
