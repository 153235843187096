import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { CanActivate } from '@angular/router';
import { UserData } from '../interfaces/user';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, AppRoutes } from 'src/app/app.constants';
import { QueryCustom } from 'src/interfaces/query';
import { QueryService } from './query.service';

@Injectable({
    providedIn: 'root',
})
export class UserService implements CanActivate {
    userData: UserData;
    constructor(
        private http: HttpClient,
        private router: Router,
        private queryService: QueryService
    ) { }

    canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            if (localStorage.getItem(LOCAL_STORAGE.token) && !this.userData) {
                this.getMe()
                    .subscribe(resp => {
                        if (resp.role === 'admin') {
                            this.setUser(resp);
                            return resolve(true);
                        }
                        this.router.navigate([AppRoutes.login]);
                        return resolve(false);
                    }, () => {
                        this.router.navigate([AppRoutes.login]);
                        return resolve(false);
                    });
            } else if (this.userData) {
                return resolve(true);
            } else {
                this.router.navigate([AppRoutes.login]);
                return resolve(false);
            }
        });
    }

    public login(data: { username: string, password: string }): Observable<any> {
        return this.http.post(`${environment.api_url}api/login`, data);
    }

    public getMe(): Observable<any> {
        return this.http.get(`${environment.api_url}api/me`);
    }

    public create(user: UserData): Observable<any> {
        return this.http.post(`${environment.api_url}api/user`, user);
    }

    public update(user: UserData): Observable<any> {
        return this.http.put(`${environment.api_url}api/user/${user.id}`, user);
    }

    public delete(id: number): Observable<any> {
        return this.http.delete(`${environment.api_url}api/user/${id}`);
    }

    public getUsers(query: QueryCustom): Observable<any> {
        const query_url = this.queryService.encodeQueryData(query);
        return this.http.get(`${environment.api_url}api/user?${query_url}`);
    }

    public logout() {
        localStorage.removeItem(LOCAL_STORAGE.token);
        this.userData = null;
        this.router.navigate([AppRoutes.login]);
    }

    public setUser(data: UserData) {
        if (data.token) {
            localStorage.setItem(LOCAL_STORAGE.token, data.token);
            delete data.token;
        }
        this.userData = data;
    }

}
