import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { CategoryData } from '../interfaces/category';
import { QueryCustom } from 'src/interfaces/query';
import { QueryService } from './query.service';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    constructor(
        private http: HttpClient,
        private queryService: QueryService,
    ) { }

    public create(category: CategoryData): Observable<any> {
        return this.http.post(`${environment.api_url}api/category`, category);
    }

    public update(category: CategoryData): Observable<any> {
        return this.http.put(`${environment.api_url}api/category/${category.id}`, category);
    }

    public delete(id: number): Observable<any> {
        return this.http.delete(`${environment.api_url}api/category/${id}`);
    }

    public getSubCategories(parent: number = null, no_parent: boolean = false): Observable<any> {
        const parent_url = parent ? `parent=${parent}` : '';
        const no_parent_url = no_parent ? `no_parent=${no_parent}` : '';
        return this.http.get(`${environment.api_url}api/categories?${parent_url}${no_parent_url}`);
    }

    public getCategories(query: QueryCustom): Observable<any> {
        const query_url = this.queryService.encodeQueryData(query);
        return this.http.get(`${environment.api_url}api/category/list?${query_url}`);
    }

    public getParent(query?: QueryCustom): Observable<any> {
        const query_url = this.queryService.encodeQueryData(query);
        return this.http.get(`${environment.api_url}api/category?limit=100&${query_url}`);
    }

    public changeOrder(categories: any[]): Observable<any> {
        return this.http.post(`${environment.api_url}api/category/order`, { categories });
    }
}
