import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { UserData } from '../../../../src/interfaces/user';
import { UserService } from '../../../../src/services/user.service';
import { del } from 'selenium-webdriver/http';
import { VALIDATE_EMAIL } from 'src/app/app.constants';

@Component({
    selector: 'app-create-user',
    templateUrl: 'create-user.component.html',
    styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
    public user: UserData;
    userLogin: UserData;
    total_number_cuts: number;
    number_cuts_min: number;
    errors = {
        username: false,
        password: false,
        total_number_cuts: false,
        passwordLength: false,
        usernameLength: false,
        email: false
    };

    constructor(
        private userService: UserService,
        public dialogRef: MatDialogRef<CreateUserComponent>) { }

    ngOnInit() {
        this.userLogin = this.userService.userData;
        if (!this.user) {
            this.user = {
                name: null,
                total_number_cuts: 0,
                number_cuts: 0,
                username: null,
                password: null,
                role: 'agent',
                active: true,
                email: null
            };
        }
    }

    public clearError(key: string) {
        this.errors[key] = this.errors[key] ? !this.errors[key] : this.errors[key];
    }

    public clearErrors() {
        this.errors.username = this.errors.username ? !this.errors.username : this.errors.username;
        this.errors.usernameLength = this.errors.usernameLength ? !this.errors.usernameLength : this.errors.usernameLength;
        this.errors.password = this.errors.password ? !this.errors.password : this.errors.password;
        this.errors.passwordLength = this.errors.passwordLength ? !this.errors.passwordLength : this.errors.passwordLength;
    }

    public editUser() {
        const { username, password, email, name, number_cuts, id } = this.user;
        if (!username) {
            this.errors.username = true;
            return;
        }
        this.errors.username = false;
        if (username.length < 4) {
            this.errors.usernameLength = true;
            return;
        }
        this.errors.usernameLength = false;
        if (!password && !id) {
            this.errors.password = true;
            return;
        }
        this.errors.password = false;
        if (password && password.length < 4) {
            this.errors.passwordLength = true;
            return;
        }
        this.errors.passwordLength = false;
        if (email && !VALIDATE_EMAIL.test(String(email).toLowerCase())) {
            this.errors.email = true;
            return;
        }
        if (!email) {
            delete this.user.email;
        }
        if (!name) {
            delete this.user.name;
        }
        if (!this.total_number_cuts) {
            delete this.user.total_number_cuts;
            delete this.user.number_cuts;
        } else {
            this.user.number_cuts = number_cuts + this.total_number_cuts;
            this.user.total_number_cuts = number_cuts;
        }
        if (this.number_cuts_min) {
            delete this.user.total_number_cuts;
            this.user.number_cuts = number_cuts - this.number_cuts_min;
        }

        if (id && password && password.length < 4) {
            delete this.user.password;
        }
        this.dialogRef.close(this.user);
    }

}
