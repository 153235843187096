import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { UserData } from '../../../interfaces/user';
import { CreateUserComponent } from 'src/app/components/create-user/create-user.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AlertsService } from 'angular-alert-module';

@Component({
  selector: 'app-dash-topnav',
  templateUrl: './topnav.component.html',
  styles: [`
    @media screen and (max-width: 991px) {
        .navbar-brand {
            width: unset !important;
            padding-left: 1rem !important;
        }
    }
  `]
})
export class TopnavComponent implements OnInit {
  user: UserData;
  modalUser: MatDialogRef<CreateUserComponent>;
  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    private alerts: AlertsService
  ) { }

  ngOnInit() {
    this.user = this.userService.userData;
  }

  public logout() {
    this.userService.logout();
  }

  public update() {
    this.modalUser = this.dialog.open(CreateUserComponent, {
      width: '350px',
      disableClose: false
    });
    this.modalUser.componentInstance.user = this.user;
    this.modalUser.afterClosed()
      .subscribe(result => {
        if (result) {
          this.editUser(result);
        }
        this.modalUser = null;
      });
  }

  private editUser(editUser: UserData) {
    this.userService.update(editUser)
      .subscribe(res => {
        this.user = res;
        this.userService.userData = res;
        this.alerts.setMessage('Editat cu succes!', 'success');
      });
  }

}
