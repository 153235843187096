import { MatDialogRef, MatDialog } from '@angular/material';
import { Component } from '@angular/core';
import { ConfirmImgComponent } from '../confirm-image/confirm-image.component';

@Component({
    selector: 'app-view-item',
    templateUrl: 'view-item.component.html',
    styleUrls: ['./view-item.component.scss']
})
export class ViewItemComponent {
    public item: any;
    dialogImage: MatDialogRef<ConfirmImgComponent>;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ViewItemComponent>) { }

    public viewImage(url: string) {
        this.dialogImage = this.dialog.open(ConfirmImgComponent, {
            disableClose: false,
            maxHeight: '96vh'
        });
        this.dialogImage.componentInstance.url = url;
        this.dialogImage.afterClosed()
            .subscribe(() => {
                this.dialogImage = null;
            });
    }

}
