import { Component, OnInit } from '@angular/core';
import { DesignService } from '../../../services/design.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DesignData } from '../../../../src/interfaces/design';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes, LOCAL_STORAGE } from 'src/app/app.constants';
import { CategoryData } from '../../../../src/interfaces/category';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../src/environments/environment';
import { CategoryService } from 'src/services/category.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfirmImgComponent } from 'src/app/components/confirm-image/confirm-image.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AlertsService } from 'angular-alert-module';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent implements OnInit {
  dialogImage: MatDialogRef<ConfirmImgComponent>;
  dialogConfirm: MatDialogRef<ConfirmDialogComponent>;
  id: string;
  read = false;
  design: DesignData;
  categories: CategoryData[];
  subCategories: CategoryData[];
  years: string[] = [];
  public uploader: FileUploader = new FileUploader({
    url: `${environment.api_url}api/upload-files`,
    authTokenHeader: 'token',
    authToken: localStorage.getItem(LOCAL_STORAGE.token)
  });
  index = 0;
  removeFiles = [];
  errors = {
    brand_id: false,
    model: false,
    category_id: false,
    images: false,
    allImages: []
  };

  constructor(
    public dialog: MatDialog,
    private designService: DesignService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
    private alerts: AlertsService,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.route.queryParams
      .subscribe(params => {
        this.read = params && params.read;
      });
    if (!this.id) {
      this.goBack();
    } else {
      this.spinner.show();
      this.getInitialData();
    }
    if (!this.read) {
      this.setYears();
      this.getCategories();
      this.initializeUpload();
    }
  }

  public onChange() {
    this.getCategories(this.design.category_id);
  }

  public goBack() {
    this.removeFiles = [];
    this.router.navigate([AppRoutes.userDashboard, AppRoutes.designs]);
  }

  public clearError(key: string) {
    this.errors[key] = this.errors[key] ? !this.errors[key] : this.errors[key];
  }

  public clearErrors(key: string) {
    this.errors.allImages[key].title = false;
  }

  private initializeUpload() {
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      if (response) {
        const images = JSON.parse(response);
        if (images && images.length) {
          const url = images[0];
          if (url.split('.').pop() === 'svg') {
            this.design.images[this.index].svg = url;
            this.setImage(url, this.index);
            this.errors.allImages[this.index].svg = false;
          } else {
            this.design.images[this.index].image = url;
            this.errors.allImages[this.index].image = false;
          }
          this.errors.images = false;
        }
      }
    };
  }

  private getInitialData() {
    if (this.id === 'new') {
      this.design = {
        brand_id: null,
        model: null,
        image: null,
        category_id: null,
        year: null,
        images: [{ title: null, image: null, svg: null }]
      };
      this.errors.allImages.push({ title: false, image: null, svg: false });
      this.spinner.hide();
    } else {
      this.getDesignList();
    }
  }

  private async setImage(url: string, index: number) {
    const preview: any = await this.designService.getSvg(url);
    const image = document.getElementById(`preview${index}`);
    if (image) {
      image['src'] = preview;
    }
  }

  private setPreviews(sides: any) {
    sides.forEach((device, index) => {
      this.setImage(device.svg, index);
    });
  }

  private async getDesignList() {
    this.designService.getDesign(this.id)
      .subscribe(resp => {
        this.design = resp;
        this.setPreviews(this.design.images);
        this.design.images.forEach(() => {
          this.errors.allImages.push({ title: false, image: null, svg: false });
        });

        if (this.design.category_id && !this.read) {
          this.getCategories(this.design.category_id);
        }
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  public viewImage(url: string) {
    this.dialogImage = this.dialog.open(ConfirmImgComponent, {
      disableClose: false,
      maxHeight: '96vh'
    });
    this.dialogImage.componentInstance.url = url;
    this.dialogImage.afterClosed()
      .subscribe(() => {
        this.dialogImage = null;
      });
  }

  public addDesign() {
    this.errors.images = false;
    this.errors.allImages.push({ title: false, image: null, svg: false });
    this.design.images.push({ title: '', image: null, svg: null });
  }

  public uploadImage(key: number = null) {
    if (this.uploader.getNotUploadedItems().length) {
      this.index = key;
      this.uploader.uploadAll();
    }
  }

  private setYears() {
    const maxOffset = 15;
    const thisYear = (new Date()).getFullYear();
    for (let x = 0; x <= maxOffset; x++) {
      this.years.push((thisYear - x).toString());
    }
  }

  private getCategories(parent: number = null) {
    this.categoryService.getSubCategories(parent)
      .subscribe(res => {
        parent ? this.subCategories = res :
          this.categories = res;
      });
  }

  public editDesign(design: DesignData) {
    if (!design.brand_id) {
      this.errors.brand_id = true;
      return;
    }
    this.errors.brand_id = false;
    if (!design.model) {
      this.errors.model = true;
      return;
    }
    this.errors.model = false;
    if (!design.category_id) {
      this.errors.category_id = true;
      return;
    }
    this.errors.category_id = false;
    if (!design.images.length) {
      this.errors.images = true;
      return;
    } else {
      let existError = false;
      design.images.map((model, index) => {
        if (!model.title) {
          this.errors.allImages[index].title = true;
          existError = true;
        }
        if (!model.image) {
          this.errors.allImages[index].image = true;
          existError = true;
        }
        if (!model.svg) {
          this.errors.allImages[index].svg = true;
          existError = true;
        }
      });
      if (existError) {
        return;
      }
    }
    this.errors.images = false;
    design.images.forEach(image => {
      delete image.preview;
    });
    design.id ? this.updateDesign(design) : this.createDesign(design);
  }

  private createDesign(design: DesignData) {
    this.designService.create(design)
      .subscribe(res => {
        this.deleteFiles();
        this.design = res;
        this.alerts.setMessage('Creat cu succes!', 'success');
        this.setPreviews(res.images);
      });
  }

  private updateDesign(editDesign: DesignData) {
    this.designService.update(editDesign)
      .subscribe(res => {
        this.deleteFiles();
        this.design = res;
        this.setPreviews(res.images);
        this.alerts.setMessage('Editat cu succes!', 'success');
      });
  }

  private deleteFiles() {
    if (this.removeFiles && this.removeFiles.length) {
      this.designService.removeFiles(this.removeFiles)
        .subscribe(() => {
          this.removeFiles = [];
        });
    }
  }

  public removeDesign(key: number) {
    this.dialogConfirm = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });
    this.dialogConfirm.componentInstance.confirmMessage = 'Ești sigur că vrei să ștergi?';
    this.dialogConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.removeFiles = [...this.removeFiles, this.design.images[key].svg, this.design.images[key].image];
        this.design.images.splice(key, 1);
      }
      this.dialogConfirm = null;
    });
  }

  public removeLogo(index: number, key: string) {
    this.dialogConfirm = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });
    this.dialogConfirm.componentInstance.confirmMessage = 'Ești sigur că vrei să ștergi?';
    this.dialogConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.removeFiles.push(this.design.images[index][key]);
        this.design.images[index][key] = '';
      }
      this.dialogConfirm = null;
    });
  }

}

