import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <ngx-spinner
    bdColor="rgba(51,51,51,0.4)"
    size="medium"
    color="#fff"
    type="ball-clip-rotate">
  </ngx-spinner>
  <app-alerts></app-alerts>
  `
})
export class AppComponent {
}
