import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { DesignService } from 'src/services/design.service';

@Component({
    selector: 'app-confirm-image',
    templateUrl: 'confirm-image.component.html',
    styleUrls: ['./confirm-image.component.scss']
})
export class ConfirmImgComponent implements OnInit {
    public url: string;

    constructor(
        private designService: DesignService,
        public dialogRef: MatDialogRef<ConfirmImgComponent>) { }

    ngOnInit() {
        if (this.url) {
            this.setImage();
        } else {
            this.dialogRef.close(false);
        }
    }

    private async setImage() {
        if (this.url.split('.').pop() === 'svg') {
            const preview: any = await this.designService.getSvg(this.url);
            const image = document.getElementById(`full-view`);
            if (image) {
                image['src'] = preview;
            }
        } else {
            const image = document.getElementById(`full-view`);
            if (image) {
                image['src'] = this.url;
            }
        }
    }
}
