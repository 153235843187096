import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class QueryService {
    public encodeQueryData(data) {
        return Object.keys(data).filter(key => data[key]).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&');
    }
}
