import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { DesignData } from '../interfaces/design';
import { QueryCustom } from 'src/interfaces/query';
import { QueryService } from './query.service';
import { Buffer } from 'buffer';

@Injectable({
    providedIn: 'root',
})
export class DesignService {
    filter = {
        offset: 0,
        limit: 10,
        search: null,
        order: null,
        orderBy: null,
        parentId: null,
        categoryId: null,
    };

    constructor(
        private http: HttpClient,
        private queryService: QueryService
    ) { }

    public create(design: DesignData): Observable<any> {
        return this.http.post(`${environment.api_url}api/design`, design);
    }

    public update(design: DesignData): Observable<any> {
        return this.http.put(`${environment.api_url}api/design/${design.id}`, design);
    }

    public delete(id: number): Observable<any> {
        return this.http.delete(`${environment.api_url}api/design/${id}`);
    }

    public getDesigns(query: QueryCustom): Observable<any> {
        const query_url = this.queryService.encodeQueryData(query);
        return this.http.get(`${environment.api_url}api/design?${query_url}`);
    }

    public getDesign(id: string): Observable<any> {
        return this.http.get(`${environment.api_url}api/design/${id}`);
    }

    public getSvg(url: string) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    const ab: any = xhr.response;
                    try {
                        if (!ab || !ab.byteLength) {
                            return resolve(url);
                        }
                        const buf = Buffer.alloc(ab.byteLength);
                        const view = new Uint8Array(ab);
                        for (let k = 0; k < buf.length; ++k) {
                            buf[k] = view[k];
                        }
                        const l = buf.length;
                        const fileName: any = url.substring(url.lastIndexOf('/') + 1);
                        for (let byte = 0; byte < l; byte++) {
                            for (let i = 0; i < fileName.length; i++) {
                                buf[byte] = buf[byte] ^ fileName[i];
                            }
                        }
                        let binary = '';
                        const bytes = new Uint8Array(buf);
                        if (!bytes || !bytes.byteLength) {
                            return resolve(url);
                        }
                        const len = bytes.byteLength;
                        for (let j = 0; j < len; j++) {
                            binary += String.fromCharCode(bytes[j]);
                        }
                        return resolve(`data:image/svg+xml;base64, ${window.btoa(binary)}`);
                    } catch (e) {
                        return resolve(url);
                    }
                }
            };
            xhr.send('');
        });
    }

    public removeFiles(files: string[]) {
        return this.http.put(`${environment.api_url}api/upload-files`, { files });
    }

}
