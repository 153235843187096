import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ConfirmDialogComponent } from "../../../app/components/confirm-dialog/confirm-dialog.component";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DesignService } from "../../../services/design.service";
import { DesignData } from "../../../interfaces/design";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app.constants";
import { AlertsService } from "angular-alert-module";
import { CategoryData } from "src/interfaces/category";
import { CategoryService } from "src/services/category.service";

@Component({
  selector: "app-designs",
  templateUrl: "./designs.component.html",
  styles: ["td,th { width: 20%;text-align: center;}select{width:200px;}"],
})
export class DesignsComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  designs: DesignData[];
  designCount = 0;
  totalPages = 0;
  currentPage = 0;
  categories: CategoryData[];
  removeFiles: any[] = [];
  categoriesParent: CategoryData[];
  filter = {
    offset: 0,
    limit: 10,
    search: null,
    order: null,
    orderBy: null,
    parentId: null,
    categoryId: null,
  };
  sortTable = {
    model: {
      orderBy: "model",
      order: null,
    },
    year: {
      orderBy: "year",
      order: null,
    },
  };
  @ViewChild("searchRef") searchRef: ElementRef;

  constructor(
    private designService: DesignService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    private alerts: AlertsService,
    private categoryService: CategoryService
  ) {}

  ngOnInit() {
    this.filter = { ...this.designService.filter };
    this.spinner.show();
    this.getDesignList();
    const obs = fromEvent(this.searchRef.nativeElement, "keyup").pipe(
      debounceTime(500)
    );
    obs.subscribe(() => this.searchDesign());
    this.getCategoryParents();
    this.getCategories();
  }

  public getCategoryParents() {
    this.categoryService.getParent({}).subscribe((res) => {
      this.categoriesParent = res.categories;
      console.log(res);
    });
  }

  public getCategories() {
    this.categoryService
      .getSubCategories(this.filter.parentId, !this.filter.parentId)
      .subscribe((res) => {
        this.categories = res;
      });
  }

  public searchDesign() {
    this.filter.offset = 0;
    this.spinner.show();
    this.getDesignList();
  }

  public clearSearch() {
    this.filter.search = null;
    this.spinner.show();
    this.getDesignList();
  }

  public sortList(key: string) {
    this.sortTable[key].order =
      this.sortTable[key].order === "asc" ? "desc" : "asc";
    this.filter.orderBy = key;
    this.filter.order = this.sortTable[key].order;
    if (key === "year") {
      this.sortTable.model.order = null;
    } else {
      this.sortTable.year.order = null;
    }
    this.spinner.show();
    this.getDesignList();
  }

  public getDesignsBrand() {
    this.spinner.show();
    this.getDesignList();
  }

  public getDesigns() {
    this.spinner.show();
    this.getCategories();
    this.getDesignList();
  }

  private getDesignList() {
    this.designService.getDesigns(this.filter).subscribe(
      (resp) => {
        this.designs = resp.designs;
        this.designCount = parseInt(resp.designs_count, 10);
        this.currentPage =
          Math.ceil(this.filter.offset / this.filter.limit) + 1;
        this.totalPages = Math.ceil(this.designCount / this.filter.limit);
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  public prevPage() {
    this.filter.offset -= this.filter.limit;
    this.spinner.show();
    this.getDesignList();
  }

  public nextPage() {
    this.filter.offset += this.filter.limit;
    this.spinner.show();
    this.getDesignList();
  }

  public openModalDesign(id: number = null) {
    this.router.navigate([
      AppRoutes.userDashboard,
      AppRoutes.designs,
      id ? id : "new",
    ]);
  }

  public openDevice(id: number) {
    this.designService.filter = { ...this.filter };
    this.router.navigate([AppRoutes.userDashboard, AppRoutes.designs, id], {
      queryParams: { read: true },
    });
  }

  private removeDesign(id: number) {
    this.designService.delete(id).subscribe(() => {
      this.designCount -= 1;
      const design = this.designs.find((item) => item.id === id);
      design.images.forEach((model) => {
        this.removeFiles = [...this.removeFiles, model.svg, model.image];
      });
      this.designs = this.designs.filter((item) => item.id !== id);
      this.deleteFiles();
      this.alerts.setMessage("Sters cu succes!", "success");
    });
  }

  private deleteFiles() {
    if (this.removeFiles && this.removeFiles.length) {
      this.designService.removeFiles(this.removeFiles).subscribe(() => {
        this.removeFiles = [];
      });
    }
  }

  public deleteDesign(id: number) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.confirmMessage =
      "Ești sigur că vrei să ștergi?";
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeDesign(id);
      }
      this.dialogRef = null;
    });
  }
}
