import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '../app.constants';
import { UserService } from '../../services/user.service';
import { environment } from 'src/environments/environment';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errorLogin = false;
  validation_messages = {
    username: [
      { type: 'required', message: 'Numele de utilizator este necesar' },
      { type: 'minLength', message: 'Numele de utilizator trebuie sa contina cel putin 4 caractere' }
    ],
    password: [
      { type: 'required', message: 'Parolă este necesara' },
      { type: 'minLength', message: 'Parola trebuie sa contina cel putin 4 caractere' }
    ]
  };

  constructor(
    private router: Router,
    private userService: UserService) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl(environment.production ? '' : 'gicu',
        Validators.compose([
          Validators.required,
          Validators.minLength(4)
        ])),
      password: new FormControl(environment.production ? '' : 'gicu123',
        Validators.compose([
          Validators.required,
          Validators.minLength(4)
        ]))
    });
  }

  doLogin(data) {
    this.userService.login(data)
      .subscribe(resp => {
        if (resp.role === 'admin') {
          this.userService.setUser(resp);
          this.router.navigate([AppRoutes.userDashboard]);
        }
      }, (err) => {
        if (err.status === 401) {
          this.errorLogin = true;
        }
      });
  }

}
