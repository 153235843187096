import { MatDialogRef, MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { CategoryData } from '../../../interfaces/category';
import { environment } from '../../../environments/environment';
import { FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { LOCAL_STORAGE } from '../../../app/app.constants';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmImgComponent } from '../confirm-image/confirm-image.component';
import { CategoryService } from '../../../../src/services/category.service';

@Component({
    selector: 'app-create-category',
    templateUrl: 'create-category.component.html',
    styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {
    public category: CategoryData;
    public parent: boolean;
    categories: CategoryData[];
    dialogImage: MatDialogRef<ConfirmImgComponent>;
    dialogConfirm: MatDialogRef<ConfirmDialogComponent>;
    public uploader: FileUploader = new FileUploader({
        url: `${environment.api_url}api/upload-files`,
        authTokenHeader: 'token',
        authToken: localStorage.getItem(LOCAL_STORAGE.token)
    });
    errors = {
        name: false,
        image: false
    };

    constructor(
        private categoryService: CategoryService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CreateCategoryComponent>) { }

    ngOnInit() {
        if (!this.parent) {
            this.getCategories();
        }
        if (!this.category) {
            this.category = {
                name: null,
                image: null
            };
        }
        this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            if (response) {
                const images = JSON.parse(response);
                if (images && images.length) {
                    this.category.image = images[0];
                    this.errors.image = false;
                }
            }
        };
    }

    public clearError() {
        this.errors.name = this.errors.name ? !this.errors.name : this.errors.name;
    }

    public viewImage(url: string) {
        this.dialogImage = this.dialog.open(ConfirmImgComponent, {
            disableClose: false,
            maxHeight: '96vh'
        });
        this.dialogImage.componentInstance.url = url;
        this.dialogImage.afterClosed()
            .subscribe(() => {
                this.dialogImage = null;
            });
    }

    public editCategory(category: CategoryData) {
        if (!category.name) {
            this.errors.name = true;
            return;
        }
        this.errors.name = false;
        if (!category.image) {
            this.errors.image = true;
            return;
        }
        if (!category.parent_id) {
            delete category.parent_id;
        }
        if (!category.parent) {
            delete category.parent;
        }
        this.errors.image = false;
        this.dialogRef.close(category);
    }

    public removeLogo() {
        this.dialogConfirm = this.dialog.open(ConfirmDialogComponent, {
            disableClose: false
        });
        this.dialogConfirm.componentInstance.confirmMessage = 'Ești sigur că vrei să ștergi?';
        this.dialogConfirm.afterClosed().subscribe(result => {
            if (result) {
                this.category.image = null;
            }
            this.dialogConfirm = null;
        });
    }

    public uploadImage() {
        if (this.uploader.getNotUploadedItems().length) {
            this.uploader.uploadAll();
        }
    }

    private getCategories() {
        this.categoryService.getSubCategories()
            .subscribe((res: CategoryData[]) => {
                this.categories = res;
            });
    }

}
