import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from '../../../app/components/confirm-dialog/confirm-dialog.component';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CreateCategoryComponent } from '../../../app/components/create-category/create-category.component';
import { CategoryData } from '../../../interfaces/category';
import { CategoryService } from '../../../services/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { ViewItemComponent } from 'src/app/components/view-item/view-item.component';
import { AlertsService } from 'angular-alert-module';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styles: ['td,th { width: 33%;text-align: center;}']
})
export class CategoriesComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  viewCategory: MatDialogRef<ViewItemComponent>;
  modalCategory: MatDialogRef<CreateCategoryComponent>;
  categories: CategoryData[];
  categoryCount = 0;
  limit = 10;
  offset = 0;
  totalPages = 0;
  currentPage = 0;
  search: string;
  order: string;
  orderBy: string;
  sortTable = {
    name: {
      orderBy: 'name',
      order: null
    },
    created_at: {
      orderBy: 'created_at',
      order: null
    },
  };
  @ViewChild('searchRef') searchRef: ElementRef;

  constructor(
    private categoryService: CategoryService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private alerts: AlertsService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.getCategoryList();
    const obs = fromEvent(this.searchRef.nativeElement, 'keyup')
      .pipe(debounceTime(500));
    obs.subscribe(() => this.searchCategory());
  }

  public searchCategory() {
    this.offset = 0;
    this.spinner.show();
    this.getCategoryList();
  }

  public clearSearch() {
    this.search = null;
    this.spinner.show();
    this.getCategoryList();
  }

  public sortList(key: string) {
    this.sortTable[key].order = (this.sortTable[key].order === 'asc') ? 'desc' : 'asc';
    this.orderBy = key;
    this.order = this.sortTable[key].order;
    if (key === 'created_at') {
      this.sortTable.name.order = null;
    } else {
      this.sortTable.created_at.order = null;
    }
    this.spinner.show();
    this.getCategoryList();
  }

  private getCategoryList() {
    this.categoryService.getParent({
      search: this.search,
      order: this.order,
      orderBy: this.orderBy
    })
      .subscribe(resp => {
        this.categories = resp.categories.map(category => {
          return {
            ...category,
            created: moment(category.created_at).format('DD-MM-YYYY HH:mm:ss')
          };
        });
        this.categoryCount = parseInt(resp.categories_count, 10);
        this.currentPage = Math.ceil(this.offset / this.limit) + 1;
        this.totalPages = Math.ceil(this.categoryCount / this.limit);
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  public openCategory(category: CategoryData) {
    this.viewCategory = this.dialog.open(ViewItemComponent, {
      width: '350px',
      disableClose: false
    });
    this.viewCategory.componentInstance.item = category;
    this.viewCategory.afterClosed()
      .subscribe(() => {
        this.viewCategory = null;
      });
  }

  public prevPage() {
    this.offset -= this.limit;
    this.spinner.show();
    this.getCategoryList();
  }

  public nextPage() {
    this.offset += this.limit;
    this.spinner.show();
    this.getCategoryList();
  }

  private createCategory(category: CategoryData) {
    this.categoryService.create(category)
      .subscribe(res => {
        if (this.categories.length > 9) {
          this.categories.pop();
        }
        this.categories = [{
          ...res,
          created: moment(category.created_at).format('DD-MM-YYYY HH:mm:ss')
        }, ...this.categories];
        this.categoryCount += 1;
        this.currentPage = Math.ceil(this.offset / this.limit) + 1;
        this.totalPages = Math.ceil(this.categoryCount / this.limit);
        this.alerts.setMessage('Creata cu succes!', 'success');
      });
  }

  public openModalCategory(category: CategoryData = null) {
    this.modalCategory = this.dialog.open(CreateCategoryComponent, {
      width: '350px',
      disableClose: false
    });
    this.modalCategory.componentInstance.category = category;
    this.modalCategory.componentInstance.parent = true;
    this.modalCategory.afterClosed().subscribe(result => {
      if (result) {
        result.id ? this.editCategory(result) : this.createCategory(result);
      }
      this.modalCategory = null;
    });
  }

  private editCategory(editCategory: CategoryData) {
    delete editCategory.created;
    this.categoryService.update(editCategory)
      .subscribe(res => {
        this.categories = this.categories.map((category) => {
          if (category.id === res.id) {
            return {
              ...res,
              created: moment(category.created_at).format('DD-MM-YYYY HH:mm:ss')
            };
          }
          return category;
        });
        this.alerts.setMessage('Editata cu succes!', 'success');
      });
  }

  private removeCategory(id: number) {
    this.categoryService.delete(id)
      .subscribe(() => {
        this.categoryCount -= 1;
        this.categories = this.categories.filter(category => category.id !== id);
        this.alerts.setMessage('Stearsa cu succes!', 'success');
      });
  }

  public deleteCategory(id: number) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = 'Ești sigur că vrei să ștergi?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeCategory(id);
      }
      this.dialogRef = null;
    });
  }

  public listOrderChanged(event: CategoryData[]) {
    const newUpdated = [];
    event.forEach((category, index) => {
      newUpdated.push({ id: category.id, order_index: index + 1 });
    });
    this.updateOrder(newUpdated);
  }

  private updateOrder(categories: any[]) {
    this.categoryService.changeOrder(categories)
      .subscribe(() => {
        this.alerts.setMessage('Editata cu succes!', 'success');
      });
  }
}
