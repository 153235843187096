// const path for app routing (changeable)
export const AppRoutes = {
    login: 'login',
    register: 'register',
    userDashboard: 'dashboard',
    users: 'users',
    categories: 'categories',
    sub_categories: 'sub-categories',
    designs: 'designs',
    design: 'design'
};

export const LOCAL_STORAGE = {
    token: 'cut_token'
};
export const VALIDATE_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

