import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UserService } from './user.service';
import { LOCAL_STORAGE } from '../app/app.constants';
import { AlertsService } from 'angular-alert-module';

@Injectable({
    providedIn: 'root',
})

export class InterceptorService implements HttpInterceptor {

    constructor(
        private userService: UserService,
        private alerts: AlertsService
    ) {
        this.alerts.setDefaults('timeout', 1);
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        let authReq = req;
        if (this.getToken() != null && req.url.indexOf('api/login') === -1) {
            authReq = req.clone({ headers: req.headers.set('token', this.getToken()) });
        }

        return next.handle(authReq).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                console.log('ERROR --------->', error);
                if ([401, 403].includes(error.status)) {
                    this.userService.logout();
                } else {
                    if (error.error.errors && error.error.errors.length) {
                        error.error.errors.forEach(err => {
                            this.alerts.setMessage(err.message.length > 50 ? err.message.substring(0, 45) + ' ...' : err.message, 'error');
                        });
                    } else {
                        this.alerts.setMessage('Error', 'error');
                    }
                }
                return throwError(error);
            })

        );

    }

    private getToken() {
        return localStorage.getItem(LOCAL_STORAGE.token);
    }

}
