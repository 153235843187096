import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/services/user.service';
import { UserData } from 'src/interfaces/user';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from '../../../app/components/confirm-dialog/confirm-dialog.component';
import { CreateUserComponent } from 'src/app/components/create-user/create-user.component';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewItemComponent } from 'src/app/components/view-item/view-item.component';
import { AlertsService } from 'angular-alert-module';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styles: ['td,th { width: 20%;text-align: center;}']
})
export class UsersComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  modalUser: MatDialogRef<CreateUserComponent>;
  viewUser: MatDialogRef<ViewItemComponent>;
  users: UserData[];
  userCount = 0;
  limit = 10;
  offset = 0;
  order: string;
  orderBy: string;
  totalPages = 0;
  currentPage = 0;
  search: string;
  sortTable = {
    name: {
      orderBy: 'name',
      order: null
    },
    role: {
      orderBy: 'role',
      order: null
    },
    active: {
      orderBy: 'active',
      order: null
    },
    number_cuts: {
      orderBy: 'number_cuts',
      order: null
    }
  };
  sortTableList = ['name', 'role', 'active', 'number_cuts'];
  @ViewChild('searchRef') searchRef: ElementRef;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private alerts: AlertsService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.getUserList();
    const obs = fromEvent(this.searchRef.nativeElement, 'keyup')
      .pipe(debounceTime(500));
    obs.subscribe(() => this.searchUser());
  }

  public searchUser() {
    this.offset = 0;
    this.spinner.show();
    this.getUserList();
  }

  public clearSearch() {
    this.search = null;
    this.spinner.show();
    this.getUserList();
  }

  private getUserList() {
    this.userService.getUsers({
      offset: this.offset,
      limit: this.limit,
      search: this.search,
      order: this.order,
      orderBy: this.orderBy
    })
      .subscribe(resp => {
        this.users = resp.users;
        this.userCount = parseInt(resp.users_count, 10);
        this.currentPage = Math.ceil(this.offset / this.limit) + 1;
        this.totalPages = Math.ceil(this.userCount / this.limit);
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  public prevPage() {
    this.offset -= this.limit;
    this.spinner.show();
    this.getUserList();
  }

  public nextPage() {
    this.offset += this.limit;
    this.spinner.show();
    this.getUserList();
  }

  public sortList(key: string) {
    this.sortTable[key].order = (this.sortTable[key].order === 'asc') ? 'desc' : 'asc';
    this.orderBy = key;
    this.order = this.sortTable[key].order;
    const resetSort = this.sortTableList.filter(index => index !== key);
    resetSort.forEach(i => {
      this.sortTable[i].order = null;
    });
    this.spinner.show();
    this.getUserList();
  }

  private createUser(user: UserData) {
    this.userService.create(user)
      .subscribe(res => {
        if (this.users.length > 9) {
          this.users.pop();
        }
        this.users = [res, ...this.users];
        this.userCount += 1;
        this.currentPage = Math.ceil(this.offset / this.limit) + 1;
        this.totalPages = Math.ceil(this.userCount / this.limit);
        this.alerts.setMessage('Creat cu succes!', 'success');
      });
  }

  public openModalUser(user: UserData = null) {
    this.modalUser = this.dialog.open(CreateUserComponent, {
      width: '350px',
      disableClose: false
    });
    this.modalUser.componentInstance.user = user;
    this.modalUser.afterClosed().subscribe(result => {
      if (result) {
        result.id ? this.editUser(result) : this.createUser(result);
      }
      this.modalUser = null;
    });
  }

  private editUser(editUser: UserData) {
    this.userService.update(editUser)
      .subscribe(res => {
        this.users = this.users.map((user) => {
          if (user.id === res.id) {
            return res;
          }
          return user;
        });
        this.alerts.setMessage('Editat cu succes!', 'success');
      });
  }

  public openViewUser(user: UserData) {
    this.viewUser = this.dialog.open(ViewItemComponent, {
      width: '350px',
      disableClose: false
    });
    this.viewUser.componentInstance.item = user;
    this.viewUser.afterClosed()
      .subscribe(() => {
        this.viewUser = null;
      });
  }

  private removeUser(id: number) {
    this.userService.delete(id)
      .subscribe(() => {
        this.userCount -= 1;
        this.users = this.users.filter(user => user.id !== id);
        this.alerts.setMessage('Sters cu succes!', 'success');
      });
  }

  public deleteUser(id: number) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = 'Ești sigur că vrei să ștergi?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeUser(id);
      }
      this.dialogRef = null;
    });
  }

}
