import { Component, OnInit } from '@angular/core';
import { AppRoutes } from '../app.constants';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {

  AppRoutes = AppRoutes;
  r = {
    name: null,
    username: null,
    password: null,
    rePassword: null
  };

  constructor() { }

  ngOnInit() {
  }

  doReg() {
    // to do
  }

}
