import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutes } from './app.constants';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UsersComponent } from './user-dashboard/users/users.component';
import { CategoriesComponent } from './user-dashboard/categories/categories.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { DesignsComponent } from './user-dashboard/designs/designs.component';
import { UserService } from '../services/user.service';
import { DesignComponent } from './user-dashboard/design/design.component';
import { SubCategoriesComponent } from './user-dashboard/sub-categories/sub-categories.component';

const routes: Routes = [
    { path: '', redirectTo: AppRoutes.userDashboard, pathMatch: 'full' },
    {
        path: AppRoutes.login,
        component: LoginComponent,
    },
    {
        path: AppRoutes.register,
        component: RegisterComponent
    },
    {
        path: AppRoutes.userDashboard,
        component: UserDashboardComponent,
        canActivate: [UserService],
        children: [
            { path: '', redirectTo: AppRoutes.users, pathMatch: 'full' },
            { path: AppRoutes.users, component: UsersComponent },
            { path: AppRoutes.categories, component: CategoriesComponent },
            { path: AppRoutes.sub_categories, component: SubCategoriesComponent },
            { path: AppRoutes.designs, component: DesignsComponent },
            { path: 'designs/:id', component: DesignComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
